import React from "react";
import home from "./home.module.css";

function Footer() {
  return (
    <div className={home.footer} >
      
    <a
      href="https://mohamed-alkfrawy.github.io/portfolio/"
      
      >
      created by Mohamed Alkfrawy
    </a>
      </div>
  );
}

export default Footer;
